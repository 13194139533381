<template>
  <fw-layout wide back-to="/manage">
    <template #main-content>
      <fw-panel :title="$t('appointments')" featured subtitle="Medicina do Trabalho">
        <template v-if="isAdmin" #toolbar>
          <span class="flex flex-1 justify-end">
            <fw-button type="transparent-primary" @click.native="openModalAppointmentType">{{
              $t('add')
            }}</fw-button></span
          >
        </template>

        <PanelManageAppointments :show-filters="false" @searching="hideStatsOnSearching = true">
          <!-- <template v-if="!hideStatsOnSearching" #stats>
          <div class="flex gap-10 my-5 ml-5">
            <PanelStats :title="'Números'" :stats="stats" :loading="loadingStats" />
          </div>
        </template> -->
        </PanelManageAppointments>
      </fw-panel>
    </template>

    <template #modals>
      <fw-modal v-if="isModalActive" :active.sync="isModalActive" :can-cancel="true" @close="closeModal">
        <template #default>
          <ModalChooseAppointmentType @create="createAppointment" @close="closeModal"></ModalChooseAppointmentType>
        </template>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import PanelManageAppointments from '@/components/panels/manage/occupationalMedicine/PanelManageAppointments'
// import PanelStats from '@/fw-modules/fw-core-vue/ui/components/panels/PanelStats'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import ModalChooseAppointmentType from '@/components/modals/ModalChooseAppointmentType'

export default {
  components: {
    PanelManageAppointments,
    ModalChooseAppointmentType,
    // PanelStats,
  },

  data() {
    return {
      hideStatsOnSearching: false,
      loadingStats: false,
      loading: false,
      stats: [
        {
          label: '',
          value: 10,
          valueSuffix: null,
          description: 'Total',
        },
        {
          label: '',
          value: 3,
          valueSuffix: null,
          description: 'Coisas',
        },
        {
          label: '',
          value: 7,
          valueSuffix: null,
          description: 'Coisitas',
        },
      ],
      isModalActive: false,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    isAdmin() {
      return this.user.roles && this.user.roles.includes('splus-admin')
    },
  },

  methods: {
    closeModal() {
      this.isModalActive = false
    },

    openModalAppointmentType() {
      this.isModalActive = true
    },

    async createAppointment() {
      try {
        const result = await this.api.createAppointment()
        this.loading = false
        console.log('createAppointment :>> ', result)
        this.$router.push({ name: 'manage-appointment', params: { key: result.key } })
      } catch (error) {
        console.error(error)
        const errorKey = utils.errors(error).getKey()
        console.log('Error KEY', errorKey)
        this.$buefy.dialog.alert({
          title: this.$t('errorOccurred.title'),
          message: this.$t('errorOccurred.message'),
          type: 'is-danger',
        })

        this.loading = false
        this.loadingError = true
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "appointments": "Chamadas para consultas",
    "add": "Criar nova chamada",
    "spacePrefixAlreadyExists": {
      "title": "Prefixo já definido",
      "message": "Já existe um processo com o prefixo que tentou definir. Por favor, indique outro para guardar as alterações."
    },
    "errorOccurred": {
      "title": "Ocorreu um erro",
      "message": "Ocorreu um erro não esperado ao guardar o procedimento. Por favor, contacte a nossa equipa de suporte."
    }
  },
  "en": {
    "appointments": "Medical Appointments",
    "add": "Create new appointment call",
    "spacePrefixAlreadyExists": {
      "title": "Prefix already set",
      "message": "There is already a space with the prefix you tried to set. Please specify another one to save your changes."
    },
    "errorOccurred": {
      "title": "An error has occurred",
      "message": "An unexpected error occurred while saving the appointment. Please contact our support team."
    }
  }
}
</i18n>
