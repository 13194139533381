<template>
  <fw-panel :title="'Criar nova chamada'">
    <div>
      <fw-label>Escolha o tipo de consultas</fw-label>
      <b-select v-model="type" placeholder="Tipo de procedimento" expanded>
        <option v-for="option in typeOptions" :key="option.value" :value="option.value">
          {{ option.label }}
        </option>
      </b-select>
    </div>
    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="$emit('close')">
        {{ 'Cancelar' }}
      </fw-button>
      <fw-button :type="'primary'" :disable="false" class="w-28" @click.native="$emit('create', type)">
        {{ 'Criar' }}
      </fw-button>
    </div>
  </fw-panel>
</template>
<script>
export default {
  data() {
    return {
      type: 'occupational_medicine',
      typeOptions: [
        {
          label: 'Medicina do trabalho',
          value: 'occupational_medicine',
        },
      ],
    }
  },
}
</script>
