<template>
  <section>
    <ContextualSearch
      v-if="showSearch"
      :loading="loading"
      :show-filters="false"
      :filter-options="filters"
      :applied-filters="appliedFilters"
      :applied-sort="orderByValue"
      :applied-sort-direction="orderDirection"
      :multifilter="multifilter"
      :start-value="searchInput"
      :start-period="startPeriod"
      :end-period="endPeriod"
      :order-by-options="orderBy"
      :show-time-period="true"
      :time-period-label="$t('createdDate')"
      :can-close="activeModal === null"
      @sort-order-changed="sortOrderChanged"
      @search="search"
    >
      <template #tags>
        <FilterTag
          v-for="(filter, f) in appliedFilters"
          :key="'filter_' + f"
          :text="getFilterText(filter)"
          :show-close-button="true"
          @close="deleteFilter(f)"
        ></FilterTag>
        <FilterTag
          v-if="startPeriod != null && endPeriod != null"
          :text="startPeriod + ' - ' + endPeriod"
          :show-close-button="true"
          @close="deleteDates()"
        ></FilterTag>
      </template>
    </ContextualSearch>

    <slot name="stats" />

    <fw-panel
      :title="$t('results')"
      :counter="appointments ? appointments.length : 0"
      :counter-total="totalResults"
      boxed
      class="my-5"
      custom-class="bg-white"
    >
      <fw-panel-info
        v-if="!loading && (!appointments || !appointments.length)"
        type="basic"
        class="text-center my-5 text-gray-500"
      >
        {{ $t('noappointments') }}
      </fw-panel-info>

      <LoadingPlaceholder v-if="loading" />
      <div v-else>
        <div v-for="item in appointments" :key="item.key" class="p-1 border-b border-gray-100">
          <RecordAppointment
            :appointment="item"
            :user="users[item.user_key]"
            @open="goToappointment(item.key)"
          ></RecordAppointment>
        </div>
      </div>
    </fw-panel>

    <BlockPagination
      v-if="totalPages > 1 && !loading"
      :per-page="limit"
      :total="totalResults"
      :total-pages="totalPages"
      :current.sync="page"
      @page-changed="pageChanged"
    />
  </section>
</template>

<script>
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag.vue'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import RecordAppointment from '@/components/records/RecordAppointment'

import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    ContextualSearch,
    FilterTag,
    BlockPagination,
    LoadingPlaceholder,
    RecordAppointment,
  },
  props: {
    showFilters: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    multifilter: {
      type: Boolean,
      default: true,
    },
    maxNumberSpaces: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      filterByType: 'all',
      filters: [
        {
          key: 'state',
          label: this.$t('appointmentStatus.label'),
          options: [
            {
              key: 'reset',
              label: this.$t('all'),
            },
            {
              key: 'canceled',
              label: this.$t('appointmentStatus.canceled'),
            },
            {
              key: 'published',
              label: this.$t('appointmentStatus.published'),
            },
            {
              key: 'draft',
              label: this.$t('appointmentStatus.draft'),
            },
          ],
        },
        {
          key: 'type',
          label: this.$t('appointmentType.label'),
          options: [
            {
              key: 'reset',
              label: this.$t('all'),
            },
            {
              key: 'scholarship',
              label: this.$t('appointmentType.scholarship'),
            },
          ],
        },
      ],

      orderBy: [
        {
          key: 'end_date',
          label: this.$t('orderBy.endDate'),
          type: 'string',
        },
        {
          key: 'start_date',
          label: this.$t('orderBy.startDate'),
          type: 'string',
        },
        {
          key: 'created_at',
          label: this.$t('orderBy.createdDate'),
          type: 'date',
        },
      ],
      searchInput: '',
      orderByValue: 'created_at',
      orderDirection: 'DESC',
      appliedFilters: [],
      startPeriod: null,
      endPeriod: null,
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 25,
      appointments: [],
      users: {},
      loading: true,

      activeModal: null,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
  },

  mounted() {
    utils.sleep(100).then(() => {
      this.getUrlParams()
      this.getappointments()
    })
  },

  methods: {
    goToappointment(key) {
      this.$router.push({ name: 'manage-appointment', params: { key: key } })
    },

    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.setUrlParams()
      this.getappointments()
    },

    deleteDates() {
      this.startPeriod = null
      this.endPeriod = null
      this.setUrlParams()
      this.getappointments()
    },

    getFilterText(key) {
      return utils.getFilterText(key, this.filters)
    },

    search(data) {
      this.appliedFilters = JSON.parse(JSON.stringify(data.filters))
      this.searchInput = data.term

      if (data.orderBy != null) {
        this.orderByValue = data.orderBy
        this.orderDirection = data.orderDirection
      }

      this.startPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[0]) : null
      this.endPeriod = data.dates.length == 2 ? this.$options.filters.formatDate(data.dates[1]) : null

      this.$emit('searching')

      this.setUrlParams()
      this.getappointments()
    },

    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }

      if (this.$route.query.f) {
        this.appliedFilters = this.$route.query.f.split(',')
      }

      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }

      if (this.$route.query.p) {
        this.page = parseInt(this.$route.query.p)
      }

      if (this.$route.query.start) {
        this.startPeriod = this.$route.query.start
      }

      if (this.$route.query.end) {
        this.endPeriod = this.$route.query.end
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.appliedFilters.length > 0) {
        query['f'] = this.appliedFilters.join(',')
      }

      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }

      if (this.startPeriod != null && this.endPeriod != null) {
        query['start'] = this.startPeriod
        query['end'] = this.endPeriod
      }

      query['p'] = this.page

      this.$router.push({ path: this.$route.path, query: query })
    },

    sortOrderChanged(newSort) {
      if (newSort != null && newSort.key != null) {
        this.orderByValue = newSort.key.key
        this.orderDirection = newSort.direction
      }
      this.setUrlParams()
      this.getappointments()
    },

    async getappointments() {
      this.loading = true
      const maxNumber = this.maxNumberSpaces != null ? this.maxNumberSpaces : this.limit
      const query = { limit: maxNumber, page: this.page, ...utils.setFiltersQuery(this.appliedFilters) }
      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.startPeriod && this.endPeriod) {
        query['created_start'] = this.parseDatesForPayload(this.startPeriod)
        query['created_end'] = this.parseDatesForPayload(this.endPeriod)
      }

      try {
        const result = await this.api.getAppointments(query)
        console.log('getappointments :>> ', result)
        this.totalResults = result.pagination?.total_items
        this.totalPages = result.pagination?.total_pages
        this.page = result.pagination?.current_page
        this.appointments = result.appointments
        this.users = result.users
      } catch (error) {
        console.error('getappointments Error: ', error)
      }

      this.loading = false
    },

    pageChanged(page) {
      console.log('pageChanged to :>> ', page)
      if (page) this.page = page
      this.setUrlParams()
      this.getappointments()
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return Dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "createdDate": "Data de criação",
    "results": "Resultados",
    "loadMoreData": "Ver mais resultados",
    "noappointments": "Sem procedimentos a mostrar.",
    "spaceType": {
      "label": "Tipo de espaço",
      "development": "Desenvolvimento",
      "support": "Suporte"
    },
    "all": "Todos",
    "orderBy": {
      "shortName": "Nome do espaço",
      "spaceStatus": "Estado do espaço",
      "createdDate": "Data de criação",
      "spaceType": "Tipo de espaço",
      "title": "Titulo do espaço",
      "prefix": "Prefixo do espaço",
      "endDate": "Data de fim",
      "startDate": "Data de início"
    },
    "appointmentStatus": {
      "label": "Estado do procedimento",
      "canceled": "Cancelado",
      "published": "Publicado",
      "draft": "Rascunho"
    },
    "appointmentType": {
      "label": "Tipo de procedimento",
      "scholarship": "Apoio",
      "occupational_medicine": "Medicina do Trabalho",
      "internship": "Estágio",
      "job": "Emprego",
      "other": "Outro"
    }
  },
  "en": {
    "createdDate": "Creation date",
    "results": "Results",
    "noappointments": "No appointments to present.",
    "loadMoreData": "Load more data",
    "spaceType": {
      "label": "Space type",
      "development": "Development",
      "support": "Support",
      "other": "Other"
    },
    "all": "All",
    "orderBy": {
      "shortName": "Space short name",
      "spaceStatus": "Space status",
      "createdDate": "Creation date",
      "spaceType": "Space type",
      "title": "Space title",
      "prefix": "Space prefix",
      "endDate": "End date",
      "startDate": "Start date"
    },
    "appointmentStatus": {
      "label": "appointment status",
      "canceled": "Cancelado",
      "published": "Published",
      "draft": "Draft"
    },
    "appointmentType": {
      "label": "appointment type",
      "scholarship": "Scholarship",
      "occupational_medicine": "Occupational Medicine",
      "internship": "Internship",
      "job": "Job",
      "other": "Other"
    }
  }
}
</i18n>
